<template>
    <div>
        <div id="modal-attacker" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Attacker
                        <button type="button" class="btn btn-close btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref="form_attacker" @submit.prevent="submitForm()">
                            <div class="row mb-1">
                                <div class="col-4" align="right">
                                    <label>Name</label>
                                </div>
                                <div class="col-8" align="left">
                                    <input name="name" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="attackerDetail && attackerDetail.name || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-4" align="right">
                                    <label>Host</label>
                                </div>
                                <div class="col-8" align="left">
                                    <input name="host" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="attackerDetail && attackerDetail.host || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-4" align="right">
                                    <label>SSH Port</label>
                                </div>
                                <div class="col-8" align="left">
                                    <input name="ssh_port" type="number" class="form-control form-control-sm" required="required" aria-required="true" :value="attackerDetail && attackerDetail.ssh_port || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-4" align="right">
                                    <label>SSH User</label>
                                </div>
                                <div class="col-8" align="left">
                                    <input name="ssh_username" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="attackerDetail && attackerDetail.ssh_username || null"/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-4" align="right">
                                    <label>SSH Private Key</label>
                                </div>
                                <div class="col-8" align="left">
                                    <textarea name="ssh_pkey" class="form-control form-control-sm" required="required" aria-required="true" :value="attackerDetail && attackerDetail.ssh_pkey || null"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" align="center">
                                    <button type="button" class="btn btn-danger btn-sm m-1" @click="onClickAttackerDelete" v-if="attackerDetail">Delete</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="!attackerDetail">Create</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="attackerDetail">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import slugify from 'slugify'
import { Modal } from 'bootstrap'

export default {
    name: 'PentestAttackerOpen',
    props: {
        attacker_id: Number
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters('pentest', [
            'getAttackerById'
        ]),
        attackerDetail() {
            return this.getAttackerById(this.attacker_id);
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createAttacker',
            'updateAttacker',
            'deleteAttacker',
            'updateSelectedProp'
        ]),
        updateSelectedAttacker(attacker_id) {
            this.updateSelectedProp({prop: 'selected_attacker', value: attacker_id});
        },
        closeModal() {
            Modal.getInstance(document.getElementById('modal-attacker')).hide();
        },
        submitForm() {
            var data = {
                name: this.$refs.form_attacker.name.value,
                host: this.$refs.form_attacker.host.value,
                ssh_port: this.$refs.form_attacker.ssh_port.value,
                ssh_username: this.$refs.form_attacker.ssh_username.value,
                ssh_pkey: this.$refs.form_attacker.ssh_pkey.value
            }
            data.slug = slugify(data.name, {'replacement': '-', 'lower': true});
            if (!this.attackerDetail) {
                this.createAttacker(data).then((res) => {
                    this.updateSelectedAttacker(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateAttacker({attacker: this.attackerDetail, data}).then((res) => {
                    this.updateSelectedAttacker(res.id);
                    this.closeModal();
                });
            }
        },
        onClickAttackerDelete() {
            var is_ok = confirm("Delete Attacker ?");
            if (is_ok) {
                this.deleteAttacker(this.attackerDetail).then(() => {
                    this.updateSelectedAttacker(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>