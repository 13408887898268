<template>
    <div>
        <div id="modal-target" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Target
                        <button type="button" class="btn btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref="form_target" @submit.prevent="onClickSubmitForm">
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Name</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="name" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="targetDetail && targetDetail.name || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Host</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="host" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="targetDetail && targetDetail.host || null"/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-3" align="right">
                                    <label>Path</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="path" type="text" class="form-control form-control-sm" :value="targetDetail && targetDetail.path || '/'"/>
                                </div>
                            </div>
                            <div class="row" v-if="this.target_id">
                                <div class="col">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Service</th>
                                                <th class="text-center">Port</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="target_service in targetServices" :key="target_service.id">
                                                <td class="text-center">{{ target_service.service.name }}</td>
                                                <td class="text-center">{{ target_service.port.number }}:{{ target_service.port.protocol }}</td>
                                                <td>
                                                    <i class="bi-x-square orange" title="Delete" @click="onClickTargetServiceDelete(target_service.id)"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <select class="form-select form-select-sm" @input="onInputTargetService(parseInt($event.target.value))">
                                                        <option v-for="option in optionsServices" :value="option.value" :key="option.value">
                                                            {{ option.text }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select class="form-select form-select-sm" @input="onInputTargetPort(parseInt($event.target.value))">
                                                        <option v-for="option in optionsPorts" :value="option.value" :key="option.value">
                                                            {{ option.text }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>   
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" align="center">
                                    <button type="button" class="btn btn-danger btn-sm m-1" @click="onClickTargetDelete" v-if="targetDetail">Delete</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="!targetDetail">Create</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="targetDetail">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import slugify from 'slugify'
import { Modal } from 'bootstrap'

export default {
    name: 'PentestTargetOpen',
    props: {
        target_id: Number
    },
    data() {
        return {
            form_service: null,
            form_port: null,
            optionsPorts: []
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'services',
            'ports'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getServiceById',
            'getTargetById',
            'getTargetServiceById',
            'getTargetServicesByTargetId',
            'getTasksByTargetId'
        ]),
        targetDetail() {
            return this.getTargetById(this.target_id);
        },
        targetServices() {
            return this.getTargetServicesByTargetId(this.target_id);
        },
        optionsServices() {
            var options = this.services.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createTarget',
            'updateTarget',
            'deleteTarget',
            'createTargetService',
            'deleteTargetService',
            'updateSelectedProp'
        ]),
        onInputTargetService(service_id) {
            var options = [{value: null, text: ''}];
            if (service_id) {
                var service = this.getServiceById(service_id);
                if (service)
                    var target_service_ports = this.targetServices.filter(target_service => target_service.service.id === service_id).map(x => x.port.id);
                    options = options.concat(service.default_ports.filter(port => !target_service_ports.includes(port.id)).map(x => ({value: x.id, text: x.number + ':' + x.protocol})));
            };
            this.form_service = service_id;
            this.form_port = null;
            this.optionsPorts = options;
        },
        onInputTargetPort(port_id) {
            if (port_id) {
                var data = {
                    target_id: this.target_id,
                    service_id: this.form_service,
                    port_id: port_id
                }
                this.createTargetService(data).then(() => {
                    this.form_service = null;
                    this.form_port = null;
                    this.optionsPorts = [];
                });
            };
        },
        updateSelectedTarget(target_id) {
            this.updateSelectedProp({prop: 'selected_target', value: target_id});
        },
        closeModal() {
            Modal.getInstance(document.getElementById('modal-target')).hide();
        },
        onClickSubmitForm() {
            var data = {
                name: this.$refs.form_target.name.value,
                host: this.$refs.form_target.host.value,
                path: this.$refs.form_target.path.value,
            }
            data.slug = slugify(data.name, {'replacement': '-', 'lower': true});
            if (!this.targetDetail) {
                this.createTarget(data).then((res) => {
                    this.updateSelectedTarget(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateTarget({target: this.targetDetail, data}).then((res) => {
                    this.updateSelectedTarget(res.id);
                    this.closeModal();
                });
            }
        },
        onClickTargetServiceDelete(target_service_id) {
            var target_service = this.getTargetServiceById(target_service_id);
            if (target_service)
                this.deleteTargetService(target_service);
        },
        onClickTargetDelete() {
            var is_ok = confirm("Delete Target ?");
            if (is_ok) {
                this.deleteTarget(this.targetDetail).then(() => {
                    this.getTasksByTargetId(this.target_id).forEach(task => this.$store.commit('pentest/DELETE_TASK', task));
                    this.updateSelectedTarget(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>