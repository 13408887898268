<template>
    <div>
        <div class="row mb-4">
            <div class="col">
                <router-link class="btn btn-secondary" to="/pentest/new">New</router-link>
            </div>
        </div>
        <PentestTaskSearch @updated-search="onUpdatedSearch"/>
        <div class="row">
            <div class="col">
                <table class="table table-hover" v-if="tasks.length">
                    <thead>
                        <tr>
                            <th>Attacker</th>
                            <th>Target</th>
                            <th>Service</th>
                            <th>Action</th>
                            <th>Created</th>
                            <th>State</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="taskDetail in tasks" :key="taskDetail.id">
                            <td>{{ taskDetail.attacker.name }}</td>
                            <td>{{ taskDetail.target.name }}</td>
                            <td>{{ taskDetail.target_service && taskDetail.target_service.service.name + ' (' + taskDetail.target_service.port.number + ':' + taskDetail.target_service.port.protocol + ')' }}</td>
                            <td>{{ taskDetail.action.name }}</td>
                            <td><timeago :datetime="taskDetail.create_date" :auto-update="60"></timeago></td>
                            <td>{{ getTaskState(taskDetail.id) }}</td>
                            <td>
                                <router-link :to="`/pentest/tsk/${taskDetail.id}`">
                                    <i class="bi-arrow-up-right-circle orange" title="Open"></i>
                                </router-link>
                            </td>
                            <td>
                                <i class="bi-x-square orange" title="Delete" @click="onClickTaskDelete(taskDetail)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <VueginateBootstrap
                    :total-items="task_count"
                    :current-page="selected_page"
                    :items-per-page="task_per_page"
                    :custom-styles="{ container: ['justify-content-end'] }"
                    @page-change="selectPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'

import { TokenService } from '../storage/service'
import { VueginateBootstrap } from 'vueginate'

import PentestTaskSearch from '@/components/PentestTaskSearch'

export default {
    name: 'PentestTaskList',
    components: {
        PentestTaskSearch,
        VueginateBootstrap
    },
    data() {
        return {
            task_per_page: 20,
            abort_controller: null
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'tasks',
            'task_count',
            'selected_page'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getTaskResultByTaskId'
        ])
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'loadTasks',
            'loadTasksResults',
            'deleteTask',
            'updateSelectedPage'
        ]),
        onUpdatedSearch(pageNum = 1) {
            this.abortSearch();
            this.abort_controller = new AbortController();

            this.updateSelectedPage(pageNum);

            this.loadTasks(this.abort_controller.signal)
            .then(() => {
                this.abort_controller = null;
            })
            .catch(() => {
            });
        },
        pollTaskResults() {
            this.$options.interval = setInterval(() => {
                this.loadTasksResults();
            }, 2000);
        },
        getTaskState(taskId) {
            var taskResult = this.getTaskResultByTaskId(taskId);
            if (taskResult)
                return taskResult.state;
            return 'UNKNOWN'
        },
        onClickTaskDelete(taskDetail) {
            var is_ok = confirm("Delete Task ?");
            if (is_ok) {
                this.deleteTask(taskDetail);
            };
        },
        selectPage(pageNum) {
            this.updateSelectedPage(pageNum);
            this.loadTasks();
        },
        abortSearch() {
            if (this.abort_controller) {
                this.abort_controller.abort();
            }
        }
    },
    created() {
        if (TokenService.getToken()) {
            this.pollTaskResults();
        }
    },
    beforeDestroy() {
        clearInterval(this.$options.interval);
    }
}
</script>