<template>
    <div>
        <div class="row mb-4">
            <div class="col">
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <i class="bi-search orange"></i>
                    </div>
                    <input type="search" class="form-control form-control-sm" :value="search_text" @input="onInputSearch($event.target.value, 'search_text')"/>
                </div>
            </div>
            <div class="col">
                <div class="input-group input-group-sm">
                    <span class="input-group-text">Target</span>
                    <select class="form-select form-select-sm" v-model="selected_target">
                        <option v-for="option in optionsTargets" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import Vuex from 'vuex'


export default {
    name: 'PentestTaskSearch',
    data() {
        return {
            selected_target: ""
        }
    },
    watch: {
        selected_target: function(val) {
            this.onInputSearch(val, 'search_target');
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'search_text',
            'search_target'
        ]),
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.slug, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'updateSearchParams'
        ]),
        onInputSearch: _.debounce(function(event, field) {
            let search = {
                search_text: this.search_text, 
                search_target: this.search_target
            };
            search[field] = event;
            this.updateSearchParams(search);
            this.$emit('updated-search');
        }, 300)
    }
}
</script>