<template>
    <div class="home">
        <div class="container-fluid">
            <div>
                <div class="row">
                    <div class="col mb-4">
                        <router-link to="/soup" class="orange"><b>Soup</b></router-link> (Knowledge Base)<br/>
                        <router-link to="/watch" class="orange"><b>Watch</b></router-link> (Security/Technology Watch)<br/>
                        <router-link to="/pentest" class="orange"><b>Pentest</b></router-link> (Penetration Testing Helper)<br/>
                        <router-link to="/writeup" class="orange"><b>Writeup</b></router-link> (Report Creator)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>