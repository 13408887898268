<template>
    <div>
        <div id="modal-credential" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Credential
                        <button type="button" class="btn btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref="form_credential" @submit.prevent="onClickSubmitForm">
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Type</label>
                                </div>
                                <div class="col-9" align="left">
                                    <select name="cred_type" class="form-select form-select-sm" :value="credentialDetail && credentialDetail.cred_type || 'plaintext'" required="required" aria-required="true">
                                        <option v-for="option in optionsTypes" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Username</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="username" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="credentialDetail && credentialDetail.username || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Password</label>
                                </div>
                                <div class="col-9" align="left">
                                    <textarea name="password" class="form-control form-control-sm" :value="credentialDetail && credentialDetail.password || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Target</label>
                                </div>
                                <div class="col-9" align="left">
                                    <select name="target" class="form-select form-select-sm" :value="!credentialDetail && selected_target || credentialDetail && credentialDetail.target && credentialDetail.target.id" @input="onInputCredentialTarget($event.target.value)">
                                        <option v-for="option in optionsTargets" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-3" align="right">
                                    <label>Service</label>
                                </div>
                                <div class="col-9" align="left">
                                    <select name="service" class="form-select form-select-sm" :value="!credentialDetail && targetServiceDetail && targetServiceDetail.service.id || credentialDetail && credentialDetail.service && credentialDetail.service.id">
                                        <option v-for="option in optionsServices" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" align="center">
                                    <button type="button" class="btn btn-danger btn-sm m-1" @click="onClickCredentialDelete" v-if="credentialDetail">Delete</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="!credentialDetail">Create</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="credentialDetail">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'
import Vuex from 'vuex'

export default {
    name: 'PentestCredentialOpen',
    props: {
        credential_id: Number
    },
    data() {
        return {
            optionsTypes: [
                {value: 'plaintext', text: 'Plaintext'},
                {value: 'ssh_pkey', text: 'SSH Private Key'},
                {value: 'ntlm_hash', text: 'NTLM Hash'},
                {value: 'hash', text: 'Hash'}
            ],
            form_target: null
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'services',
            'selected_target',
            'selected_target_service'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getCredentialById',
            'getTargetById',
            'getTargetServiceById'
        ]),
        credentialDetail() {
            return this.getCredentialById(this.credential_id);
        },
        targetServiceDetail() {
            return this.getTargetServiceById(this.selected_target_service);
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsServices() {
            var options = [{value: null, text: ''}];
            if (this.form_target) {
                var target = this.getTargetById(this.form_target);
                if (target)
                    options = options.concat(target.services.map(x => ({value: x.service.id, text: x.service.name + ' (' + x.port.number + ':' + x.port.protocol + ')'})));
            } else {
                options = options.concat(this.services.map(x => ({value: x.id, text: x.name})));
            }
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createCredential',
            'updateCredential',
            'deleteCredential',
            'updateSelectedProp'
        ]),
        onInputCredentialTarget(target_id) {
            this.form_target = target_id;
        },
        updateSelectedCredential(credential_id) {
            this.updateSelectedProp({prop: 'selected_credential', value: credential_id});
        },
        closeModal() {
            Modal.getInstance(document.getElementById('modal-credential')).hide();
        },
        onClickSubmitForm() {
            var data = {
                cred_type: this.$refs.form_credential.cred_type.value,
                username: this.$refs.form_credential.username.value,
                password: this.$refs.form_credential.password.value,
                target_id: this.$refs.form_credential.target.value,
                service_id: this.$refs.form_credential.service.value
            }
            if (!this.credentialDetail) {
                this.createCredential(data).then((res) => {
                    this.updateSelectedCredential(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateCredential({credential: this.credentialDetail, data}).then((res) => {
                    this.updateSelectedCredential(res.id);
                    this.closeModal();
                });
            }
        },
        onClickCredentialDelete() {
            var is_ok = confirm("Delete Credential ?");
            if (is_ok) {
                this.deleteCredential(this.credentialDetail).then(() => {
                    this.updateSelectedCredential(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>