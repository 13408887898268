<template>
    <div>
        <div class="row mb-4">
            <div class="col">
                <router-link class="btn btn-secondary" to="/pentest">Back</router-link>
            </div>
        </div>
        <div>
            <PentestAttackerOpen :attacker_id="selected_attacker"/>
           <PentestTargetOpen :target_id="selected_target"/>
           <PentestCredentialOpen :credential_id="selected_credential"/>
           <PentestParameterOpen :parameter_id="selected_parameter"/>
        </div>
        <div class="row mb-4">
            <div class="col-lg-6">
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Attacker</span>
                    <select class="form-select form-select-sm" :value="selected_attacker" @input="onInputSelected('selected_attacker', parseInt($event.target.value))">
                        <option v-for="option in optionsAttackers" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-attacker"><i class="bi-gear-fill"></i></button>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Target</span>
                    <select class="form-select form-select-sm" :value="selected_target" @input="onInputSelected('selected_target', parseInt($event.target.value))">
                        <option v-for="option in optionsTargets" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-target"><i class="bi-gear-fill"></i></button>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Credential</span>
                    <select class="form-select form-select-sm" :value="selected_credential" @input="onInputSelected('selected_credential', parseInt($event.target.value))" :disabled="selected_target === null">
                        <option v-for="option in optionsCredentials" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-credential" :disabled="selected_target === null"><i class="bi-gear-fill"></i></button>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Parameter</span>
                    <select class="form-select form-select-sm" :value="selected_parameter" @input="onInputSelected('selected_parameter', parseInt($event.target.value))" :disabled="selected_target === null">
                        <option v-for="option in optionsParameters" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-parameter" :disabled="selected_target === null"><i class="bi-gear-fill"></i></button>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Phase</span>
                    <select class="form-select form-select-sm" :value="selected_phase" @input="onInputSelected('selected_phase', parseInt($event.target.value))" :disabled="selected_target === null">
                        <option v-for="option in optionsPhases" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Service</span>
                    <select class="form-select form-select-sm" :value="selected_target_service" @input="onInputSelected('selected_target_service', parseInt($event.target.value))" :disabled="selected_target === null || selected_phase === null">
                        <option v-for="option in optionsTargetsServices" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Vuln</span>
                    <select class="form-select form-select-sm" :value="selected_vulnerability" @input="onInputSelected('selected_vulnerability', parseInt(event.target.value))" :disabled="selected_target === null || selected_phase === null">
                        <option v-for="option in optionsVulnerabilities" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text">Tool</span>
                    <select class="form-select form-select-sm" :value="selected_tool" @input="onInputSelected('selected_tool', parseInt($event.target.value))" :disabled="selected_target === null || selected_phase === null">
                        <option v-for="option in optionsTools" :value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
            </div>
            <div class="col">
                <button type="button" class="btn btn-secondary btn-sm" v-if="selected_actions.length" :disabled="selected_attacker === null || selected_target === null" @click="onClickCopyActions">
                    <i class="bi-clipboard"></i> Copy Actions
                </button>
                &nbsp;
                <button type="button" class="btn btn-primary btn-sm" :disabled="selected_phase === null" @click="onClickSearchActions">
                    <i class="bi-search"></i> Search Actions
                </button>
                &nbsp;
                <button type="button" class="btn btn-success btn-sm" v-if="selected_actions.length" :disabled="selected_attacker === null || selected_target === null" @click="onClickRunActions">
                    <i class="bi-play-fill"></i> Run Actions
                </button>
            </div>
            <div class="col">
            </div>
        </div>
        <div class="row mb-4" v-if="show_text_to_copy">
            <textarea
                ref="textarea-task-copy"
                v-on:focus="$event.target.select()"
                :value=text_to_copy
            ></textarea>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-hover" v-if="actions.length">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" v-model="select_all" @click="onClickSelectAllActions"/>
                            </th>
                            <th>Name</th>
                            <th>Vulnerability</th>
                            <th>Tool</th>
                            <th>Command</th>
                        </tr>
                    </thead>
                    <tbody> <!-- :list="actions" @end="onDragEndActionRow" -->
                        <tr v-for="a in actions" :key="a.id" @click="onClickActionRow(a.id)">
                            <td>
                                <label class="form-checkbox">
                                    <input type="checkbox" :value="a.id" v-model="selected_actions">
                                    <i class="form-icon"></i>
                                </label>
                            </td>
                            <td>{{ a.name }}</td>
                            <td>{{ a.vulnerability }}</td>
                            <td>{{ a.tool }}</td>
                            <td>{{ a.command }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import Vuex from 'vuex'

import PentestAttackerOpen from '@/components/PentestAttackerOpen.vue'
import PentestTargetOpen from '@/components/PentestTargetOpen.vue'
import PentestCredentialOpen from '@/components/PentestCredentialOpen.vue'
import PentestParameterOpen from '@/components/PentestParameterOpen.vue'

export default {
    name: 'PentestTaskCreate',
    components: {
        PentestAttackerOpen,
        PentestTargetOpen,
        PentestCredentialOpen,
        PentestParameterOpen,
    },
    data() {
        return {
            actions: [],
            selected_actions: [],
            select_all: false,
            show_text_to_copy: false,
            text_to_copy: ""
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'attackers',
            'targets',
            'credentials',
            'parameters',
            'phases',
            'services',
            'vulnerabilities',
            'tools',
            'selected_attacker',
            'selected_target',
            'selected_target_service',       
            'selected_credential',
            'selected_parameter',
            'selected_phase',
            'selected_vulnerability',
            'selected_tool'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getTargetById',
            'getTargetServiceById',
            'getTargetServicesByTargetId',
            'getActionsByPhaseAndServiceAndVulnerabilityAndTool'
        ]),
        optionsAttackers() {
            var options = this.attackers.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsCredentials() {
            var options = this.credentials.map(x => ({value: x.id, text: 'Target = ' + (x.target && x.target.name || 'Global') + ' / Service = ' + (x.service && x.service.name || 'Global') + ' / ' + x.username + ' (' + x.cred_type + ')'}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsParameters() {
            var options = this.parameters.map(x => ({value: x.id, text: 'Target = ' + (x.target && x.target.name || 'Global') + ' / Tool = ' +  (x.tool && x.tool.name || 'Global') + ' / ' + x.key}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsPhases() {
            var options = this.phases.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }, 
        optionsTargetsServices() {
            var options = [{value: null, text: ''}];
            if (this.selected_target) {
                var target = this.getTargetById(this.selected_target);
                if (target)
                    var target_services = this.getTargetServicesByTargetId(target.id);
                    if (target_services)
                        options = options.concat(target_services.map(x => ({value: x.id, text: x.service.name + ' (' + x.port.number + ':' + x.port.protocol + ')'})));
            }
            return options;
        },
        optionsVulnerabilities() {
            var options = this.vulnerabilities.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: 'Any'});
            return options;
        },
        optionsTools() {
            var options = this.tools.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: 'Any'});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'updateSelectedProp',
            'updateAction',
            'getTaskCommand',
            'createTask'
        ]),
        onInputSelected(prop, value) {
            this.updateSelectedProp({prop, value});
        },
        onClickSelectAllActions() {
            this.selected_actions = [];
            if (!this.select_all) {
                for (let x in this.actions) {
                    this.selected_actions.push(this.actions[x].id);
                }
            }
        },
        onDragEndActionRow() {
            this.actions.forEach((actionDetail, i) => {
                let data = {priority: i + 1};
                this.updateAction({action: actionDetail, data})
            });
        },
        onClickActionRow(action_id) {
            if (this.selected_actions.includes(action_id)) {
                this.selected_actions.splice(this.selected_actions.indexOf(action_id), 1);
            } else {
                this.selected_actions.push(action_id);
            }
        },
        onClickSearchActions() {
            this.selected_actions = [];
            var phase_id = this.selected_phase || null;
            var target_service_id = this.selected_target_service || null;
            var service_id = target_service_id && this.getTargetServiceById(target_service_id).service.id || null;
            var vulnerability_id = this.selected_vulnerability || null;
            var tool_id = this.selected_tool || null;
            var actions = this.getActionsByPhaseAndServiceAndVulnerabilityAndTool(phase_id, service_id, vulnerability_id, tool_id) || [];
            var actions = _.sortBy(actions, 'priority');
            this.actions = actions.map(a => ({id: a.id, name: a.name, tool: a.tool.name, service: a.service && a.service.name || null, vulnerability: a.vulnerability && a.vulnerability.name || null, auth_needed: a.auth_needed && 'Yes' || 'No', command: a.command}));
        },
        async onClickCopyActions() {
            var commands = [];
            for (let x in this.selected_actions) {
                var data = {
                    attacker_id: this.selected_attacker,
                    target_id: this.selected_target,
                    target_service_id: this.selected_target_service,
                    credential_id: this.selected_credential,
                    action_id: this.selected_actions[x]
                }
                const cmd = await this.getTaskCommand(data);
                commands.push(cmd);
            }
            this.show_text_to_copy = true;
            this.text_to_copy = commands.join('\n');
            await nextTick();
            this.$refs['textarea-task-copy'].focus();
            document.execCommand('copy');
            this.show_text_to_copy = false;
        },
        onClickRunActions() {
            for (let x in this.selected_actions) {
                var data = {
                    attacker_id: this.selected_attacker,
                    target_id: this.selected_target,
                    target_service_id: this.selected_target_service,
                    credential_id: this.selected_credential,
                    action_id: this.selected_actions[x]
                }
                this.createTask(data);
            }
            this.$router.push(`/pentest`);
        }
    }
}
</script>