<template>
    <div v-if="taskDetail" v-hotkey="mapKeys">
        <div class="row mb-4">
            <div class="col">
                <router-link class="btn btn-secondary" to="/pentest">Back</router-link>
            </div>
        </div>
        <div class="row mb-4" v-if="show_text_to_copy">
            <textarea class="form-control form-control-sm" ref="textarea-task-copy" v-on:focus="$event.target.select()" :value="text_to_copy"/>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Attacker</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.attacker.name }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Target</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.target.name }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Service</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.target_service && taskDetail.target_service.service.name + ' (' + taskDetail.target_service.port.number + ':' + taskDetail.target_service.port.protocol + ')' }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-2" align="right">
                <b>Action</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.action.name }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Credential</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.credential && taskDetail.credential.username + ' (' + taskDetail.credential.cred_type + ')' }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-2" align="right">
                <b>Vulnerability</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.action.vulnerability && taskDetail.action.vulnerability.name }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-2" align="right">
                <b>Tool</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.action.tool && taskDetail.action.tool.name }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Create Date</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.create_date }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Write Date</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.write_date }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-2" align="right">
                <b>State</b>
            </div>
            <div class="col-10" align="left">
                {{ taskResult && taskResult.state }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-2" align="right">
                <b>Command</b>
            </div>
            <div class="col-10" align="left">
                {{ taskDetail.command }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" align="right">
                <b>Output</b>
            </div>
            <div class="col-10" align="left">
                <div v-html="processedAnsi"></div>
            </div>
        </div>
        <div class="row">
            <div class="col" align="center">
                <button type="submit" class="btn btn-primary btn-sm" @click="onClickTaskCopy">Copy</button>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import Vuex from 'vuex'
import Convert from 'ansi-to-html'
import stripAnsi from 'strip-ansi'

const convert = new Convert({
    newline: true
});

export default {
    name: 'PentestTaskOpen',
    data() {
        return {
            show_text_to_copy: false,
            text_to_copy: ''
        }
    },
    computed: {
        ...Vuex.mapGetters('pentest', [
            'getTaskById',
            'getTaskResultByTaskId'
        ]),
        taskDetail() {
            return this.getTaskById(Number(this.$route.params.id));
        },
        taskResult() {
            return this.getTaskResultByTaskId(Number(this.$route.params.id));
        },
        processedAnsi() {
            if (this.taskResult) {
                return convert.toHtml(this.taskResult.output);
            }
            return '';
        },
        strippedAnsi() {
            if (this.taskResult) {
                return stripAnsi(this.taskResult.output);
            }
            return '';
        },
        mapKeys() {
            return {
                "ctrl+c": this.onClickTaskCopy
            };
        }
    },
    methods: {
        async onClickTaskCopy() {
            var text = '# Phase ' + this.taskDetail.action.phase.sequence + ' - ' + this.taskDetail.action.phase.name;
            text += '\n\n';
            text += '## Action' + ' - ' + this.taskDetail.action.name + '\n';
            text += '```' + '\n';
            text += '$ ' + this.taskDetail.command + '\n';
            text += this.strippedAnsi  + '\n';
            text += '```';
            this.show_text_to_copy = true;
            this.text_to_copy = text;
            await nextTick();
            this.$refs['textarea-task-copy'].focus();
            document.execCommand('copy');
            this.show_text_to_copy = false;
        }
    }
}
</script>