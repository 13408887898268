<template>
    <div>
        <div id="modal-parameter" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Parameter
                        <button type="button" class="btn btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref="form_parameter" @submit.prevent="onClickSubmitForm">
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Name</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="name" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="parameterDetail && parameterDetail.name || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Key</label>
                                </div>
                                <div class="col-9" align="left">
                                    <input name="key" type="text" class="form-control form-control-sm" required="required" aria-required="true" :value="parameterDetail && parameterDetail.key || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Value</label>
                                </div>
                                <div class="col-9" align="left">
                                    <textarea name="value" class="form-control form-control-sm" :value="parameterDetail && parameterDetail.value || null"/>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-3" align="right">
                                    <label>Target</label>
                                </div>
                                <div class="col-9" align="left">
                                    <select name="target" class="form-select form-select-sm" :value="!parameterDetail && selected_target || parameterDetail && parameterDetail.target && parameterDetail.target.id">
                                        <option v-for="option in optionsTargets" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-3" align="right">
                                    <label>Tool</label>
                                </div>
                                <div class="col-9" align="left">
                                    <select name="tool" class="form-select form-select-sm" :value="!parameterDetail && selected_tool || parameterDetail && parameterDetail.tool && parameterDetail.tool.id">
                                        <option v-for="option in optionsTools" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" align="center">
                                    <button type="button" class="btn btn-danger btn-sm m-1" @click="onClickParameterDelete" v-if="parameterDetail">Delete</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="!parameterDetail">Create</button>
                                    <button type="submit" class="btn btn-primary btn-sm m-1" v-if="parameterDetail">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { Modal } from 'bootstrap'

export default {
    name: 'PentestParameterOpen',
    props: {
        parameter_id: Number
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'tools',
            'selected_target',
            'selected_tool'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getParameterById'
        ]),
        parameterDetail() {
            return this.getParameterById(this.parameter_id);
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsTools() {
            var options = this.tools.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createParameter',
            'updateParameter',
            'deleteParameter',
            'updateSelectedProp'
        ]),
        updateSelectedParameter(parameter_id) {
            this.updateSelectedProp({prop: 'selected_parameter', value: parameter_id});
        },
        closeModal() {
            Modal.getInstance(document.getElementById('modal-parameter')).hide();
        },
        onClickSubmitForm() {
            var data = {
                name: this.$refs.form_parameter.name.value,
                key: this.$refs.form_parameter.key.value,
                value: this.$refs.form_parameter.value.value,
                target_id: this.$refs.form_parameter.target.value,
                tool_id: this.$refs.form_parameter.tool.value
            }
            if (!this.parameterDetail) {
                this.createParameter(data).then((res) => {
                    this.updateSelectedParameter(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateParameter({parameter: this.parameterDetail, data}).then((res) => {
                    this.updateSelectedParameter(res.id);
                    this.closeModal();
                });
            }
        },
        onClickParameterDelete() {
            var is_ok = confirm("Delete Parameter ?");
            if (is_ok) {
                this.deleteParameter(this.parameterDetail).then(() => {
                    this.updateSelectedParameter(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>